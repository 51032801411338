<template>
  <static-fullscreen-card>
    <template v-slot:title>{{ data.objects_address }} ({{ data.id }})</template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$router.push({name: 'objects_view', params: {id: data.object_id}})"
        ><btn-title icon="far fa-building">Перейти в объект</btn-title></v-btn
      >
      <v-btn v-if="data.status != 1" color="primary" @click="showSalesDialog = true"
        >Продать</v-btn
      >
      <v-btn
        color="primary"
        @click="showEditDialog = true"
        title="Редактировать элемент"
        ><btn-title icon="far fa-edit">Редактировать</btn-title></v-btn
      ></template
    >
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="id"></edit-dialog>
      <sales-dialog v-model="showSalesDialog" @save="sell()" :room_id="id" :id="0"></sales-dialog>
    </portal>
    <v-row>
      <v-col cols="12" sm="6">
        <material-card>
          <template v-slot:heading>
            <div>фото объекта</div>
          </template>
          <a-view-images :value="data.images" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card>
          <template v-slot:heading>
            <div>основная информация</div>
          </template>
          <a-form-view
            :value="data"
            :model="[model[0], model[7], model[8], model[1], model[2], model[4], model[6]]"
          />
        </material-card>
      </v-col>
      <v-col cols="12">
        <material-card color="warning">
          <template v-slot:heading>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              slider-color="white"
            >
              <v-tab v-for="(tab, i) in tabs" :key="i" class="mr-3">{{
                tab.title
              }}</v-tab>
            </v-tabs>
          </template>
          <v-tabs-items
            v-model="tab"
            class="transparent"
            style="height: 300px;"
          >
            <v-tab-item>
              <v-card-text style="height: 300px; overflow: auto">
                <a-view-files :value="data.files" />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    editDialog: () => import("./dialogs/roomDialog"),
    salesDialog: () => import("./dialogs/salesDialog"),
  },
  data() {
    return {
      id: 0,
      showEditDialog: false,
      showSalesDialog: false,
      title: "",
      data: {},
      model: this.$store.getters["config/get"].models.rooms.form,
      url: "/kazan/rooms",
      tab: 0,
      tabs: [{ title: "проектная документация" }],
    };
  },
  created() {
    this.$root.title = "Студии";
    this.id = Number(this.$route.params.id);
    this.fitchData();
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
    },
    async sell() {
      await this.$axios.post(this.url, { id: this.id, status: 1 });
      await this.fitchData();
    },
  },
};
</script>
